const initialState = {
    show: false,
    callBackFunction: null
};

export const ageGateReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_AGE_GATE':
            return {
                show: action.payload.show,
                callBackFunction: action.payload.callBackFunction
            };
        case 'RESET_AGE_GATE':
            return initialState;
        default:
            return state;
    }
};