const initialState = {
    bannerList: [],
    bestSellersList: [],
    featuredProductsList: [],
    dealsProductList: [],
    departmentList: [],
    subDepartmentList: [],
    categoryList: [],
    suggestedItemsList: [],
    shopByCategoryList: [],
    paymentMethodsList: [],
    refundOptionsList: [],
    promotionCategoryList: [],
    systemConfiguration: []
};

export const webDataCollectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_WEB_DATA_COLLECTION':
            return {
                bannerList: action.payload.bannerList,
                bestSellersList: action.payload.bestSellersList,
                featuredProductsList: action.payload.featuredProductsList,
                dealsProductList: action.payload.dealsProductsList,
                departmentList: action.payload.departmentList,
                subDepartmentList: action.payload.subDepartmentList,
                categoryList: action.payload.categoryList,
                suggestedItemsList: action.payload.suggestedItemsList,
                shopByCategoryList: action.payload.tileList,
                paymentMethodsList: action.payload.paymentMethodsList,
                refundOptionsList: action.payload.refundOptionsList,
                promotionCategoryList: action.payload.promotionCategoryList,
                systemConfiguration: action.payload.systemConfiguration,
            };
        case 'RESET_WEB_DATA_COLLECTION':
            return initialState;
        default:
            return state;
    }
};
