const initialState = {
    isRedeemed: false,
    redeemedAmount: 0,
    transactionReference: '',
    nexusReference: '',
};

export const nexusRedemptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_NEXUS_REDEMPTION_DETAIL':
            return {
                isRedeemed: true,
                redeemedAmount: action.payload.custPointsRedeemed,
                transactionReference: action.payload.trxReferance,
                nexusReference: action.payload.trxRefID
            };
        case 'RESET_NEXUS_REDEMPTION':
            return initialState;
        default:
            return state;
    }
};