import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import CacheBuster from './utility/cacheBuster';
import { IsPromotionSubDomain } from '../src/utility/environment_config';

/*-- Import required components --*/
const DefaultPage = React.lazy(() => import('./pages/defaultPage/DefaultPage'));
const LandingPage = React.lazy(() => import('./pages/landingPage/LandingPage'));
const CheckoutPage = React.lazy(() => import('./pages/checkoutPage/CheckoutPage'));
const VerifyCustomer = React.lazy(() => import("./components/verifyCustomer/VerifyCustomer"));
const PaymentGateway = React.lazy(() => import("./pages/PaymentGateway/NTB"));
const PaymentPageRedirectingHandler = React.lazy(() => import("./pages/PaymentGateway/PaymentPageRedirectingHandler"));
const AboutUs = React.lazy(() => import('./pages/aboutUs/AboutUs'));
const ThankYou = React.lazy(() => import("./pages/thankYouPage/ThankYou"));
const UtilityPaymentView = React.lazy(() => import('./pages/utilityPaymentPage/UtilityPayment'));
const TrackYourOrderView = React.lazy(() => import('./pages/trackYourOrderPage/TrackYourOrderView'));
const DeliveryInfo = React.lazy(() => import('./pages/deliveryInfoPage/DeliveryInfo'));
const FAQPage = React.lazy(() => import('./pages/faqPage/FaqPage'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacyPolicyPage/PrivacyPolicyPage'));
const TermsAndConditions = React.lazy(() => import('./pages/termsAndConditionsPage/TermsAndConditionsPage'));
const StoreLocator = React.lazy(() => import('./pages/storeLocator/StoreLocator'));
const NexusTC = React.lazy(() => import('./pages/nexusT&CPage/NexusTandC'));
const MyMedOrderTracker = React.lazy(() => import('./pages/myMedOrderTrackerPage/MyMedOrderTracker'));
const ShowcaseExternal = React.lazy(() => import('./pages/showcase/ShowcaseExternal'));
const BrandPageExternal = React.lazy(() => import('./pages/brandPage/BrandPageExternal'));
const TurnPage = React.lazy(() => import('./pages/trunPage/TurnPage'));

class App extends Component {
  render() {
    let myLoading = () => (
      <LoadingScreen isVisible={true} />
    );

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) { refreshCacheAndReload(); }
          if (!IsPromotionSubDomain) {
            return (
              <BrowserRouter>
                <React.Suspense fallback={myLoading()}>
                  <Switch>
                    <Route exact path="/thankyou/:referenceNumber/:type" name="THANK YOU" render={props => <ThankYou {...props} />} />
                    <Route exact path="/Registration/VerifyCustomer/:customerID/:key/:otp" name="VERIFY CUSTOMER" render={props => <VerifyCustomer {...props} />} />
                    <Route exact path="/welcome/:active_view/:orderNumber" name="LANDING PAGE" render={props => <LandingPage {...props} />} />
                    <Route exact path="/welcome/:active_view" name="LANDING PAGE" render={props => <LandingPage {...props} />} />
                    <Route exact path="/welcome" name="LANDING PAGE" render={props => <LandingPage {...props} />} />
                    <Route exact path="/checkout/:code" name="CHECKOUT PAGE" render={props => <CheckoutPage {...props} />} />
                    <Route exact path="/checkout" name="CHECKOUT PAGE" render={props => <CheckoutPage {...props} />} />
                    <Route exact path="/PaymentGateway" name="Payment Gateway" render={props => <PaymentGateway {...props} />} />
                    <Route exact path="/PaymentPageRedirectingHandler" name="Payment Page Redirecting Handler" render={props => <PaymentPageRedirectingHandler {...props} />} />
                    <Route exact path="/trackyourorder/:ordnum/:authfield" name="TRACK YOUR ORDER" render={props => <TrackYourOrderView {...props} />} />
                    <Route exact path="/deliveryInfo" name="DELIVERY INFO" render={props => <DeliveryInfo {...props} />} />
                    <Route exact path="/mobdeliveryInfo" name="DELIVERY INFO" render={props => <DeliveryInfo {...props} />} />
                    <Route exact path="/storeLocatorInfor" name="STORE LOCATOR" render={props => <StoreLocator {...props} />} />
                    <Route exact path="/mobstorelocator" name="STORE LOCATOR" render={props => <StoreLocator {...props} />} />
                    {/* <Route exact path="/aboutus" name="ABOUT US PAGE" render={props => <AboutUs {...props} />} /> */}
                    <Route exact path="/trackyourorder/:ordnum" name="TRACK YOUR ORDER" render={props => <TrackYourOrderView {...props} />} />
                    <Route exact path="/mobfaq" name="FAQ" render={props => <FAQPage {...props} />} />
                    <Route exact path="/mobaboutus" name="ABOUT US" render={props => <AboutUs {...props} />} />
                    <Route exact path="/mobtc" name="TERMS AND CONDITIONS" render={props => <TermsAndConditions {...props} />} />
                    <Route exact path="/mobnexustc" name="NEXUS TERMS AND CONDITIONS" render={props => <NexusTC {...props} />} />
                    <Route exact path="/mobprivacypolicy" name="PRIVACY POLICY" render={props => <PrivacyPolicy {...props} />} />
                    <Route exact path="/mymedtracker/:ordernumber/:email" name="MY MED ORDER TRACKER" render={props => <MyMedOrderTracker {...props} />} />
                    <Route exact path="/showcase/:promocode" name="SHOWCASE" render={props => <ShowcaseExternal {...props} />} />
                    <Route exact path="/showcase/items/:promocode/:type/:typeid/:popupcode" name="BRAND PAGE" render={props => <BrandPageExternal {...props} />} />
                    <Route exact path="/showcase/items/:promocode/:type/:typeid" name="BRAND PAGE" render={props => <BrandPageExternal {...props} />} />
                    <Route exact path="/showcase/items/:promocode/:popupcode" name="BRAND PAGE" render={props => <BrandPageExternal {...props} />} />
                    <Route exact path="/showcase/items/:promocode" name="BRAND PAGE" render={props => <BrandPageExternal {...props} />} />
                    <Route path="/" name="DEFAULT LAYOUT" render={props => <DefaultPage {...props} />} />
                  </Switch>
                </React.Suspense>
              </BrowserRouter>
            );
          } else {
            return (
              <BrowserRouter>
                <React.Suspense fallback={myLoading()}>
                  <Switch>
                    <Route exact path="/" name="TURN PAGE" render={props => <TurnPage {...props} />} />
                  </Switch>
                </React.Suspense>
              </BrowserRouter>
            );
          }
        }
        }
      </CacheBuster>
    )
  }

}

export default App;
