const initialState = {
  orderNumber: "",
  authorizationField: "",
  isViewableOrder: 0,
  customerName: "",
  deliveryLocationId: "",
  deliveryLocationDescription: "",
  orderAmount: 0,
  currentStatusId: 0,
  currentStatusDescription: "",
  invoiceNumber: null,
  invoiceAmount: 0,
  itemRefundAmount: 0,
  deliveryRefundAmount: 0,
  refundOptionID: 0,
  orderStatusDetails: [],
  punchedItemDetails: [],
  missingItemDetails: [],
  deliveryDetails:[],
};

export const trackOrderDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TRACKING_DETAIL':
      return {
        orderNumber: action.payload.orderNumber,
        authorizationField: action.payload.authorizationField,
        isViewableOrder: action.payload.isViewableOrder,
        customerName: action.payload.customerName,
        deliveryLocationId: action.payload.deliveryLocationId,
        deliveryLocationDescription: action.payload.deliveryLocationDescription,
        orderAmount: action.payload.orderAmount,
        currentStatusId: action.payload.currentStatusId,
        currentStatusDescription: action.payload.currentStatusDescription,
        invoiceNumber: action.payload.invoiceNumber,
        invoiceAmount: action.payload.invoiceAmount,
        itemRefundAmount: action.payload.itemRefundAmount,
        deliveryRefundAmount: action.payload.deliveryRefundAmount,
        refundOptionID: action.payload.refundOptionID,
        orderStatusDetails: action.payload.orderStatusDetails,
        punchedItemDetails: action.payload.punchedItemDetails,
        missingItemDetails: action.payload.missingItemDetails,
        deliveryDetails : action.payload.deliveryDetails,
      };
    case 'RESET_TRACKING_DETAIL':
      return initialState;
    default:
      return state;
  }
};