export const OrderTypesEnum = Object.freeze({
    ItemOrder: 1,
    UtilityPayment: 2,
})

export const LocalStorageEnum = Object.freeze({
    AppVersion: "AppVersion",
    OrderNumber: "OrderNumber",
    OrderTotal: "OrderTotal",
    OrderTypeID: "OrderTypeID",
})

export const LandingPageViewEnum = Object.freeze({
    WelcomeView: "WelcomeView",
    LoginView: "Login",
    RegistrationView: "Registration",
    TrackOrderView: "TrackOrder",
    PreferenceView: "Preference",
    ForgetPasswordView: "ForgetPassword",
    ContinueShoppingAfterUtilityPayment: "ContinueShoppingAfterUtilityPayment"
})

export const WelcomeTileTypesEnum = Object.freeze({
    LoginTile: "LoginTile",
    RegisterTile: "RegisterTile",
    TrackOrderTile: "TrackOrderTile",
    BrowseStoreTile: "BrowseStoreTile",
})

export const errorTypesEnum = Object.freeze({
    error: 1,
    information: 2,
    notification: 3,
    success: 4,
    warning: 5,
})

export const productListingTypeEnum = Object.freeze({
    listByDepartment: "1",
    listBySubDepartment: "2",
    listByCategory: "3",
    listByProductName: "4",
    listByPromotion: "5",
    listAllPromotions: "6",
})

export const productSearchLengthEnum = Object.freeze({
    productSearchLength: 0
})

export const PaymentMethods = Object.freeze({
    VisaMaster: 1,
    Amex: 2,
})

export const shoppingMethod = Object.freeze({
    pickup: "Pickup",
    delivery: "Delivery",
})

export const otherPromotionTypesEnum = Object.freeze({
    KeellsDeals: 1,
    NexusDeals: 2,
})

export const utilityPaymentTab = Object.freeze({
    utilityPayments: 1,
    myTemplates: 2,
    transcations: 3
})

export const utilityPaymentView = Object.freeze({
    categoryView: 1,
    subCategoryView: 2,
    formView: 3
})

export const responsiveScreenSizesEnum = Object.freeze({
    tabScreenSize: 768,
    mobileLargeScreen: 425,
    mobileSmallScreen: 375,
})

export const newNexusRegistrationCodeEnum = Object.freeze({
    newNexusRegistrationCode: 222222
})

export const myAccountViews = Object.freeze({
    profileEdit: 1,
    changePassword: 2,
    trackOrder: 3,
    utilityPayments: 4,
    savedCarts: 5,
    pastOrders: 6,
    shippingDetails: 7,
    refundOption: 8
})

export const orderPrefixes = Object.freeze({
    UtilityBillPayment: "UBP"
})

export const brandPromoEnum = Object.freeze({
    department: "1",
    subdepartment: "2",
    category: "3",
    brand: "4",
    item: "5",
    group: "6"
})

export const showcaseDefaultsEnum = Object.freeze({
    locationCode: "",
})