const initialState = {
    outletCode: "",
    outletName: "",
    shoppingMethod: "",
    mainOutletCode: "",
    mainOutletDescription: "",
    suburbID: 0,
};

export const preferenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SHOPPING_PREFERENCE':
            return {
                outletCode: action.payload.outletCode,
                outletName: action.payload.outletName,
                shoppingMethod: action.payload.shoppingMethod,
                mainOutletCode: action.payload.mainOutletCode,
                mainOutletDescription: action.payload.mainOutletDescription,
                suburbID: action.payload.suburbID
            };
        case 'RESET_SHOPPING_PREFERENCE':
            return initialState;
        default:
            return state;
    }
};